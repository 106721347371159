import React from "react";
import "./products.css"; // Import CSS for styling

const ProductTable = (p) => {
    const data = p.data.packing;    
    const columnNames = {
        "size": "Size (mm)",
        "diaOfRod": "Dia of Rod",
        "length": "Length (mm)",
        "weight": "Weight(Kg.)",
        "packing": "Packing (Master Cartons)"
      };
      
      // Dynamically get the columns that exist in the data
      const availableColumns = Object.keys(columnNames).filter((key) =>
        data.some((item) => item.hasOwnProperty(key))
      );
  return (
    <div  border="1" cellPadding="10" className="product-table">
      <table>
        <thead>
          <tr>
            {availableColumns.map((col) => (
              <th key={col}>{columnNames[col]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {availableColumns.map((col) => (
                <td key={col}>{row[col] !== undefined ? row[col] : "-"}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
