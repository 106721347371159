import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Products from "../pages/product/ProductComponent";
import Experience from "../pages/experience/Experience";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/Projects";
import { settings } from "../portfolio.js";
import ProductDescription from "../components/products/ProductDescription.jsx";

export default function Main(propss) {
  return (
    <Router>
      <Routes basename="/">
        <Route
          path="/"
          exact
          element={<Home theme={propss.theme} setTheme={propss.setTheme} />}
        />
        <Route
          path="/home"
          element={<Home theme={propss.theme} setTheme={propss.setTheme} />}
        />
        <Route
          path="/experience"
          exact
          element={
            <Experience theme={propss.theme} setTheme={propss.setTheme} />
          }
        />

        <Route path="/product/:id" element={<ProductDescription theme={propss.theme} setTheme={propss.setTheme}/>} />
        <Route
          path="/products"
          element={<Products theme={propss.theme} setTheme={propss.setTheme} />}
        />

        <Route
          path="/contact"
          element={<Contact theme={propss.theme} setTheme={propss.setTheme} />}
        />
        <Route
          path="/projects"
          element={<Projects theme={propss.theme} setTheme={propss.setTheme} />}
        />
      </Routes>
    </Router>
  );
}
