import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import { projectsHeader, socialMediaLinks } from "../../portfolio.js";
import "./Projects.css";
import ProjectsImg from "./iso1.png";
import { style } from "glamor";

function Projects(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="projects-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <img
                src={ProjectsImg}
                alt="home"
                className="projects-heading-img"
              />
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
              >
                {projectsHeader.title}
              </h1>
              <p
                className="projects-header-detail-text subTitle"
              >
                {projectsHeader["description1"]}
              </p>
              <p
                className="projects-header-detail-text subTitle"
              >
                {projectsHeader["description2"]}
              </p>
              <p
                className="projects-header-detail-text subTitle"
              >
                {projectsHeader["description3"]}
              </p>
              <p
                className="projects-header-detail-text subTitle"
              >
                {projectsHeader["description4"]}
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Projects;
