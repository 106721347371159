import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./Experience.css";
import { Fade } from "react-reveal";

function Experience(props) {
  const theme = props.theme;
  console.log(props.setTheme);
  return (
    <div className="experience-main container-main">
      <Header theme={theme} setTheme={props.setTheme} />

      <div className="basic-experience">
        <Fade bottom duration={2000} distance="40px">
          <div className="experience-heading-div">
            <div className="experience-heading-img-div">
              <div className="projects-heading-img-div">
                <img
                  className="profile-pic"
                  width="50%"
                  src={require(`../../assests/uploads/2008527909250131.jpg`)}
                  alt=""
                />
                <br />

                <img
                  className="profile-pic"
                  width="50%"
                  src={require(`../../assests/uploads/Machine-2.jpg`)}
                  alt=""
                />
              </div>
            </div>

            <div className="experience-heading-text-div">
              <h1 className="experience-heading-text">Technology</h1>

              <p className="experience-header-detail-text subTitle">
                The process of manufacturing involves forging by material
                gathering machines and friction screw presses –
              </p>
              <ul>
                <li>
                  Perfect grain flow. Unlike upsetters there are no kinks or
                  interruptions of natural grains
                </li>
                <li>
                  Drawn/ Peeled bar has zero ovality compared to rolled bars.
                  This ensures exact volumes of steel being heated and upset
                  each and every time
                </li>
                <li>
                  {
                    "Shift < 0.5mm further reduces machining tolerances on forgings"
                  }
                </li>
                <li>Instant heat up eliminates warm up time</li>
                <li>
                  Electric heating is from core to surface ensuring lowest scale
                  with max benefit for die life
                </li>
                <li>
                  Thyristor control ensures constant heating current. Once
                  setting is done, over heating, burning etc. is impossible
                </li>
              </ul>
              <p>
                Unit has facilities for heat treatment ( hardening and
                tempering, and Induction hardening ). The surface finish is done
                as per customer specifications. Infrastructure includes –
              </p>
              <ul>
                <li>Material Gathering Machines</li>
                <li>Resistance Heating Machine</li>
                <li>Screw Presses</li>
                <li>Copy turning lathes</li>
                <li>Column drill</li>
                <li>Straightening presses</li>
                <li>Heat Treatment furnaces</li>
                <li>Projection welding machine</li>
                <li>In house Die making</li>
                <li>Hardness Tester</li>
                <li>Torque Testing machine</li>
              </ul>
            </div>
          </div>
        </Fade>
      </div>

      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Experience;
