import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Certifications from "../../containers/certifications/Certifications";
import EducationImg from "./EducationImg";
import "./ProductComponent.css";
import { Fade } from "react-reveal";

function Product(props) {
  const theme = props.theme;
  return (
    <div className="education-main">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <div className="container-main">
        <Certifications theme={props.theme} />
      </div>
      <Footer theme={props.theme} />
    </div>
  );
}

export default Product;
