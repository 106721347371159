import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { contactPageData } from "../../portfolio.js";

const ContactData = contactPageData.contactSection;

function Contact(props) {
  const theme = props.theme;

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text"
                style={{ color: theme.text }}
              >
                {ContactData["title"]}
              </h1>
              <div className="contact-detail-text">
                Ashok Kumar Bansal
                <br />
                MM UDYOG
                <br />
                Pathankot Chowk, By-Pass G.T. Road
                <br />
                Near P.S.E.B. Office
                <br />
                Jalandhar City -144008, Punjab, India.
                <br />
                Email: akbansal@mmudyog.com, himanshu@mmudyog.com
                <br />
                Ph. +91-896-853-4208
                <br />
              </div>
            </div>
            <div className="contact-heading-img-div">
              <a
                href="https://www.google.com/maps/place/MM+Udyog/@31.3419005,75.3127435,60906m/data=!3m2!1e3!4b1!4m6!3m5!1s0x391a5a1092026737:0x606d5688ac188673!8m2!3d31.3422386!4d75.6080731!16s%2Fg%2F11tk174282?entry=ttu&g_ep=EgoyMDI1MDEyMC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="profile-pic"
                  src={require(`../../assests/uploads/${ContactData["contact_image_path"]}`)}
                  alt=""
                />
              </a>
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Contact;
