import React from "react";
import "./Hero.css";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";
import { style } from "glamor";

export default function Hero(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text">{greeting.title}</h1>
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                {greeting.subTitle}
              </p>
              <p>
                <b>Excellence in manufacturing hand tools</b>
                <br />
                Competitive Prices
                <br />
                Timely Sample Development
                <br />
                On time deliveries
                <br />
                Export Worthy Packaging
                <br />
                All Products manufactured within the plant
              </p>
            </div>
          </div>
          <div className="greeting-image-div">
            <FeelingProud theme={props} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
