import React from "react";

const SalientFeatures = (props) => {
  const features = props.props.features;

  if (features.length > 0) {
    return (
      <>
        <div>
                    <h2 style={{ fontSize: "24px", marginBottom: "10px" }}>
            Salient Features
          </h2>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            {features.map((feature, index) => (
              <li key={index} style={{ marginBottom: "8px" }}>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default SalientFeatures;
