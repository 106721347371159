/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  logo: "MMUdyogLogo.png",
  title: "MM Udyog",
  logo_name: "MM Udyog",
  subTitle: "An ISO:9001 certified Wheel Wrench company.",
  heroImage: "featured-image.png",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/hrishi1999",
  linkedin: "https://www.linkedin.com/in/hrishi-patel-ba8476140/",
  gmail: "hrishipatel99@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "https://twitter.com/hrishi_55",
  instagram: "https://www.instagram.com/picleric/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJS and Angular",
        "⚡ Developing mobile applications using Flutter and native Android",
        "⚡ Backend development using NodeJS, ExpressJS and Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#5c79df",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Go",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#00A7D0",
          },
        },
        {
          skillName: "Visual Basic",
          fontAwesomeClassname: "simple-icons:dot-net",
          style: {
            color: "#029FCE",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "simple-icons:csharp",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Android App Development",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },

        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },

    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Certified AWS Developer Associate and Cloud Practioner",
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing web apps on GCP and AWS",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Certifications by deeplearning.ai and Stanford Online",
        "⚡ Experience with 10+ Projects",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#6E6E6E",
          },
        },
      ],
    },
  ],
};



const products = {
  products: [
    {
      id: 1,
      title: "2-Way Wheel Spanner",
      logo_path: "Two-Way-Wheel-Spanner-Large.jpg",
      img: "Two-Way-Wheel-Spanner-Large.jpg",
      title: "2-Way Wheel Spanner",
      features: [
        "Torque as per Din 899",
        "Made from Carbon Steel / Chrome Vanadium Steel",
        "Full Hardened Two Way Wheel Spanner",
        "Socket Hardened Two Way Wheel Spanner",
        "Chrome Plated / Zinc Plated / Black Finish Two Way Wheel Spanners",
      ],
      packing: [
        { size: "17 x 35", length: 400, packing: 10 },
        { size: "22 x 24", length: 400, packing: 10 },
        { size: "24 x 26", length: 400, packing: 10 },
        { size: "24 x 27", length: "400/250", packing: 10 },
        { size: "27 x 27", length: 400, packing: 10 },
        { size: "27 x 30", length: 400, packing: 10 },
        { size: "27 x 32", length: 400, packing: 10 },
        { size: "27 x 33", length: 400, packing: 10 },
        { size: "30 x 30", length: 400, packing: 10 },
        { size: "30 x 32", length: 400, packing: 10 },
        { size: "30 x 33", length: 400, packing: 10 },
        { size: "32 x 32", length: 400, packing: 10 },
        { size: "32 x 33", length: 400, packing: 10 },
        { size: "33 x 33", length: 400, packing: 10 },
        { size: "21 Sq. x 38 Hex", length: 400, packing: 10 },
      ],
    },
    {
      id: 2,
      title: "Angle Socket Wrench",
      logo_path: "Angle-Socket-Wrench-Large.jpg",

      features: [],
      packing: [
        {
          size: "12 x 12",
          packing: 140,
        },
        {
          size: "13 x 13",
          packing: 100,
        },
        {
          size: "14 x 14",
          packing: 80,
        },
        {
          size: "15 x 15",
          packing: 60,
        },
        {
          size: "16 x 16",
          packing: 60,
        },
        {
          size: "17 x 17",
          packing: 60,
        },
        {
          size: "19 x 19",
          packing: 40,
        },
        {
          size: "21 x 21",
          packing: 60,
        },
        {
          size: "22 x 22",
          packing: 40,
        },
        {
          size: "23 x 23",
          packing: 60,
        },
        {
          size: "24 x 24",
          packing: 40,
        },
        {
          size: "25 x 25",
          packing: 60,
        },
        {
          size: "26 x 26",
          packing: 40,
        },
        {
          size: "27 x 27",
          packing: 60,
        },
        {
          size: "28 x 28",
          packing: 40,
        },
        {
          size: "29 x 29",
          packing: 60,
        },
        {
          size: "30 x 30",
          packing: 40,
        },
        {
          size: "31 x 31",
          packing: 60,
        },
        {
          size: "32 x 32",
          packing: 40,
        },
      ],
    },

    {
      id: 3,
      title: "Lug Wrench",
      logo_path: "Lug-Wrench-Large.jpg",
      features: [
        "Carbon Steel Spanner",
        "Made from Carbon Steel / Chrome Vanadium Steel",
        "Chrome Plated / Zinc Plated / Black Finish / Satin Finish Steel Spanners",
      ],
      packing: [
        {
          size: 17,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 18,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 19,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 21,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 22,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 23,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "13/16",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 24,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 27,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
      ],
    },
    {
      id: 4,
      title: "Cross Rim Wrench",
      logo_path: "Cross-Rim-Wrench-Large.jpg",
      features: [
        "Made from Carbon Steel",
        "With/Without Reinforcement",
        "Chrome Plated / Zinc Plated / Black Finish / Satin Finish",
      ],
      packing: [
        {
          size: "17 * 19 * 21 * 23",
          length: "350/450",
          barDia: "13 / 14 / 15 / 16",
          packing: "20/16",
        },
        {
          size: "17 * 19 * 21 * 22",
          length: "350/450",
          barDia: "13 / 14 / 15 / 16",
          packing: "20/16",
        },
        {
          size: "17 * 18 * 19 * 21",
          length: "350/450",
          barDia: "13 / 14 / 15 / 16",
          packing: "20/16",
        },
        {
          size: "17 * 19 * 22 * 13/16",
          length: "350/450",
          barDia: "13 / 14 / 15 / 16",
          packing: "20/16",
        },
        {
          size: "17 * 19 * 21 * 1/2",
          length: "350/450",
          barDia: "13 / 14 / 15 / 16",
          packing: "20/16",
        },
      ],
    },
    {
      id: 5,
      title: "Truck Wheel Wrench",
      logo_path: "Truck-Wheel-Wrench-Large.jpg",
      features: [],
      packing: [],
    },
    {
      id: 6,
      title: "L-Type Spanner",
      logo_path: "L-Type-Spanner-Large.jpg",
      features: [
        "Carbon Steel Spanner",
        "Made from Carbon Steel / Chrome Vanadium Steel",
        "Chrome Plated / Zinc Plated / Black Finish / Satin Finish Steel Spanners",
      ],
      packing: [
        {
          size: "17",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "18",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "19",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "21",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "22",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "23",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "13/16",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "24",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "27",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
      ],
    },
    {
      id: 7,
      title: "L Spanner",
      logo_path: "L-Shape-Angled-Spanners-Large.jpg",
      features: [
        "Carbon Steel Spanner",
        "Made from Carbon Steel / Chrome Vanadium Steel",
        "Chrome Plated / Zinc Plated / Black Finish / Satin Finish Steel Spanners",
      ],
      packing: [
        {
          size: 17,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 18,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 19,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 21,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 22,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 23,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: "13/16",
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 24,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
        {
          size: 27,
          diaOfRod: "13 / 14 / 15 / 16",
          length: "325/350",
          packing: 40,
        },
      ],
    },
    {
      id: 8,
      title: "Tommy Bars",
      logo_path: "Tommy-Bars-Large.jpg",
      features: [],
      packing: [
        {
          size: 19,
          length: 400,
          weight: 0.89,
          packing: 20,
        },
        {
          size: 19,
          length: 600,
          weight: 1.34,
          packing: 16,
        },
      ],
    },
    {
      id: 9,
      title: "4-Way Cross Rim Wrench",
      logo_path: "4-Way-Cross-Rim-Wrench-Large.jpg",
      features: [],
      packing: [],
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed two internships. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "CS2 - IT Developer/Analyst",
          company: "Canada Revenue Agency",
          company_url: "https://www.canada.ca/en/revenue-agency.html",
          logo_path: "cra.png",
          duration: "May 2023 - Present",
          location: "Halifax, Nova Scotia",
          description:
            "• Led development to concurrently process tax returns for multiple fiscal years, reducing system processing time by 35%\n" +
            "• Implemented new tax return types and led development of T3 returns, resolving 25+ critical production issues\n" +
            "• Collaborated across 5+ cross-functional teams to integrate tax law changes, ensuring 100% compliance\n" +
            "• Mentored 4 team members and led code review sessions, enhancing team knowledge sharing",
          color: "#f10000",
        },
        {
          title: "Full Stack Developer - Part-time Contract",
          company: "SpatialCut",
          company_url: "",
          logo_path: "spatialcut.png",
          duration: "April 2024 - Present",
          location: "Remote",
          description:
            "• Architected AWS cloud infrastructure, reducing complexity by 40% and improving scalability\n" +
            "• Developed microservices using AWS SageMaker, ECS, and EC2 Mac Instances for Apple Vision Pro platform\n" +
            "• Optimized video processing pipeline, reducing rendering time by 25%",
          color: "#2962FF",
        },
        {
          title: "Full Stack Developer",
          company: "Quicko",
          company_url: "https://quicko.com/",
          logo_path: "quicko.jpg",
          duration: "June 2021 - October 2021",
          location: "Ahmedabad",
          description:
            "• Developed PWA with Flutter serving 50,000+ customers\n" +
            "• Integrated 5+ brokerages including Zerodha, Upstox, Groww\n" +
            "• Implemented backend APIs using Node.js and Spring Boot, reducing operational costs by 20%\n" +
            "• Created responsive frontend using Angular and Vue.js",
          color: "#2962FF",
        },
        {
          title: "Python Developer",
          company: "Google Summer of Code - Sugar Labs",
          company_url: "https://sugarlabs.org/",
          logo_path: "google_logo.png",
          duration: "May 2019 - August 2019",
          location: "Remote",
          description:
            "• Implemented Dashboard functionality using Python and GTK3 for Sugar OS\n" +
            "• Created interactive widget for Sugar Desktop environment\n" +
            "• Contributed to Python 2 to 3 migration and Music Blocks features",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Google Summer of Code",
          company: "Sugar Labs",
          company_url: "https://sugarlabs.org/",
          logo_path: "google_logo.png",
          duration: "May 2019 - Aug 2019",
          location: "Work From Home",
          description:
            "I worked on the Dashboard project which helps users track their activities while using Sugar OS. I also worked on making a Tamagotchi-like widget for Sugar's Home Screen",
          color: "#ee3c26",
        },
        {
          title: "Android App Developer Intern",
          company: "IAS4Sure",
          company_url: " ",
          logo_path: "buld.jpg",
          duration: "December 2019 - February 2020",
          location: "Work From Home",
          description:
            "Internship task was to make a native Android application. ",
          color: "#0071C5",
        },
        {
          title: "Web Developer",
          company: "Wrighter Writing Solutions",
          company_url: " ",
          logo_path: "wrighter.jpg",
          duration: "August 2020",
          location: "",
          description: "Develop a website using PHP and jQuery.",
          color: "#56A4D3",
        },
        {
          title: "Front-End Developer",
          company: "VJ TechServe",
          company_url: " ",
          logo_path: "vjt.png",
          duration: "September 2020",
          location: "",
          description: "Develop a portfolio website using ReactJS",
          color: "#800000",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Summer of Code Mentor",
          company: "Sugar Labs / GSoC",
          company_url: "https://sugarlabs.org/",
          logo_path: "google_logo.png",
          duration: "Summer of 2017 / 18 / 20",
          location: " ",
          description:
            "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
          color: "#4285F4",
        },
        {
          title: "Google Code-In Mentor",
          company: "Sugar Labs / GSoC",
          company_url: "https://sugarlabs.org/",
          logo_path: "google_logo.png",
          duration: "Winter of 2017 / 18 / 19",
          location: " ",
          description:
            "Day to day resposibilites of this mentorship was to help out children aged 13-17 get started with programming and open-source, review their work and approve them.",
          color: "#D83B01",
        },
        {
          title: "Board Member at Codeuino",
          company: " ",
          company_url: " ",
          logo_path: "codeuino.jpg",
          duration: "2018 - 2019",
          location: " ",
          description: "Previous Board during early days of Codeuino",
          color: "#D83B01",
        },
        {
          title: "BluWings Club",
          company: " ",
          company_url: " ",
          logo_path: "b.png",
          duration: "2018 - On Going",
          location: " ",
          description:
            "Co-Founder and President of the programming club called BluWings. Have organized several workshops which introduced programming and related tools to freshers.",
          color: "#D83B01",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "About Us",
  description1:
    "Established in 2005, MM Udyog is located in the Industrial Area of Jalandhar City, India. We specialize in manufacturing a wide range of high-quality products, including wheel spanners, cross-wheel spanners, lug wrenches, angle socket wrenches, and hexagonal box spanners.",

  description2:
    'As an ISO 9001:2008 certified company, we are committed to delivering superior products that meet the diverse needs of our customers across the globe. We adhere to the highest standards of quality, with a focus on achieving "Zero Defects" and honoring our promise of timely delivery.',

  description3:
    "At MM Udyog, we continuously strive to improve our processes and products, ensuring accountability at every level of operation. Our state-of-the-art technology and premium raw materials enable us to offer reliable products, competitive pricing, and customized packing solutions that align with our customers’ preferences.",

  description4:
    "Our philosophy is simple: we aim to create value for our customers by providing top-notch products at competitive prices while building long-lasting relationships with both new and existing clients.",

  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact us",
    contact_image_path: "location.png",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try my best to write a blog about it.",
    link: "https://medium.com/@hrishipatel99",
    avatar_image_path: "blogs_image.svg",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  products,
  experience,
  projectsHeader,
  contactPageData,
};
