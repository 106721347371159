import React from "react";
import "./CertificationCard.css";
import { Fade } from "react-reveal";

function CertificationCard(props) {
  const certificate = props.certificate;
  const theme = props.theme;


  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="cert-card">
        <div className="content">
          <a
            href={`product/${certificate.id}`}
           
          >
            <div className="content-overlay"></div>
            <div
              className="cert-header"
            >
              <img
                className="logo_img"
                src={require(`../../assests/uploads/${certificate.logo_path}`)}
                alt={certificate.alt_name}
              />
            </div>
            {/* <div className="content-details fadeIn-top">
									<h3 className="content-title" style={{ color: theme.body }}>
										Certificate
									</h3>
								</div> */}
          </a>
        </div>
        <div className="cert-body">
          <h2 className="cert-body-title" style={{ color: theme.text }}>
            {certificate.title}
          </h2>
          
        </div>
      </div>
    </Fade>
  );
}

export default CertificationCard;
