import React from "react";
import "./Skills.css";
import { Fade } from "react-reveal";

function Products(props) {
  const theme = props.theme;
  return (
    <div className="skills-text-div">
      <Fade up duration={800}>
        <h1 className="skills-heading" style={{ color: theme.text }}>
          Products
        </h1>
      </Fade>
      <Fade up duration={800}>
        <div className="skills-text">
          We offer a wide range of 4-way cross rim wrench, L shape spanners,
          wheel nut wrenches, angle socket wrenches, solid box spanner -
          hexagonal and others that are fabricated as per customer
          specifications and international standards.
        </div>
      </Fade>
      <Fade up duration={800}>
        <div>
          <p
            className="subTitle skills-text"
            style={{ color: theme.secondaryText }}
          >
            Visit our entire product range <a href="./">Here</a>
          </p>
        </div>
      </Fade>
    </div>
  );
}

function AboutUs(props) {
  return (
    <div className="skills-text-div">
      <Fade up duration={800}>
        <h1 className="skills-heading">About Us</h1>
      </Fade>

      <Fade up duration={800}>
        <div>
          <p className="subTitle skills-text">
            Founded in 2005, MM Udyog is a ISO 9001:2008 certified company. We
            are a team of professionals and have established ourselves as a
            leading manufacturer, supplier and exporter in the industry.
          </p>
        </div>
      </Fade>
    </div>
  );
}
function ContactUs(props) {
  const theme = props.theme;
  return (
    <div className="skills-text-div">
      <Fade up duration={800}>
        <h1 className="skills-heading" style={{ color: theme.text }}>
          Contact Us
        </h1>
      </Fade>
      <Fade up duration={800}>
        <div>
          <p> Ashok Kumar Bansal</p>
          <p className="subTitle skills-text">
            MM Udyog
            <br />
            Near P.S.E.B. Office, Pathankot Chowk,
            <br />
            By-Pass G.T. Road,
            <br />
            Jalandhar - 144008,
            <br />
            Punjab, India
          </p>
        </div>
      </Fade>
    </div>
  );
}

function SkillSection(props) {
  return (
    <div className="skills-main-div">
      <Products theme={props.theme} />

      <AboutUs theme={props.theme} />

      <ContactUs theme={props.theme} />
    </div>
  );
}

export default SkillSection;
