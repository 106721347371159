import React from "react";
import { Fade } from "react-reveal";
import Header from "../header/Header";
import SalientFeatures from "./Salient";
import { useParams } from "react-router-dom";
import { products } from "../../portfolio";
import Footer from "../footer/Footer";
import "./products.css";
import ProductTable from "./ProductTable";

const ProductDescription = (props) => {
  const { id } = useParams();
  const datas = products.products[id - 1];
  const theme = props.theme;
  return (
    <div className="container-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <center>
        <h1>{datas.title}</h1>
      </center>
      <div className="information-container">
        <div className="information">
          <SalientFeatures props={datas} />
        </div>
        <div className="image">
          <img src={require(`../../assests/uploads/${datas.logo_path}`)} />
        </div>
      </div>

      <Fade bottom duration={2000} distance="20px">
        <ProductTable data={datas} />
      </Fade>
      <div>
        <Footer theme={props.theme} />
      </div>
    </div>
  );
};

export default ProductDescription;
