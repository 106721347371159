import React from "react";
import "./FeelingProud.css";
import { greeting } from "../../portfolio";
function FeelingProud(props) {
  const theme = props.theme;
  return (
    <>
      <img
        src={require(`../../assests/uploads/${greeting["heroImage"]}`)}
        alt="MMUdyog Hero Image"
        className="feeling-proud-gif"
      />
    </>
  );
}

export default FeelingProud;
